/* eslint-disable @stylistic/max-len */
const ROUTES = {
  AIRLINE: { CHANGE_RATE: (accountId) => `/account/${accountId}/airline/rates` },
  AEROEXPRESS: {
    CHANGE_AEROEXPRESS_VOUCHER: (tripItemVersionId) => `/trip-service/version/${tripItemVersionId}/blank/update`,
    POST_AEROEXPRESS_VOUCHER: (serviceType) => `/trip-service/version/${serviceType}/upload/blank`,
  },
  TRAIN: { CHANGE_RATE: (accountId) => `/account/${accountId}/train/rates` },
  BUS: {
    CHANGE_RATE: (accountId) => `/account-rate-service/rates/bus?accountId=${accountId}`,
    LOAD_RATE: '/account-rate-service/rates/bus',
  },
  APPROVAL: {
    ERA_APPROVERS: (employeeId) => `/expense-report-approval/approvers/${employeeId}`,
    ERA_APPROVAL_SCHEMES: (userId) => `/expense-report-approval/approval-schemes/${userId}`,
  },
  TRAVEL_POLICIES: {
    GET_EMPLOYEE_TP: (id) => `/travel-policies-service/policy/${id}`,
    GET_TRAVEL_POLICIES: '/travel-policies-service/policies',
  },
  HOTELS: {
    CONTRACT: {
      GET_CONTRACT: (id) => `/contract-hotel-provider/contract/${id}`,
      DELETE_CONTRACT: (id) => `/contract-hotel-provider/contract/${id}`,
      SAVE_CONTRACT: '/contract-hotel-provider/contract',
    },
    HOTEL_AGGREGATION_PROVIDERS: '/hotel-aggregation/providers',
    GET_HOTEL_AA: (id) => `/hotel-service-dictionary/aanda/hotel/${id}/info`,
    HOTEL_BOOK_REQUEST: '/hotel-manual/requests',
    GET_SAVED_BOOK_REQUESTS: (companyId) => `/hotel-manual/requests?companyId=${companyId}`,
    DELETE_SAVED_REQUEST: (requestId) => `/hotel-manual/requests/${requestId}`,
    GET_SAVED_REQUEST: (requestId) => `/hotel-manual/requests/${requestId}`,
    SEND_REQUEST_TO_HOTEL: (requestId) => `/hotel-manual/requests/${requestId}/send`,
    SEND_CUSTOM_TEMPLATE_REQUEST: (requestId) => `/hotel-manual/requests/${requestId}/letter-templates`,
    CHECK_HOTELS_MATCH: '/hotel-service-dictionary/existence-check',
    COMPARES_CHECK: '/hotel-service-dictionary/compares-check',
    GET_HOTELS_BY_GIATA_IDS: '/giata/hotel-mappings/by-giata-ids',
  },
  CUSTOM: {
    DOWNLOAD_VOUCHER_GROUP: (companyId, tripItemId) => (
      `/companies/${companyId}/trips/items/${tripItemId}/voucherxlsx`
    ),
  },
  TAXI: {
    GET_REFUND_CANCEL: (tripItemId) => `/taxi-bo/${tripItemId}/refund`,
    CANCEL_VOUCHER: (tripItemId) => `/taxi-bo/${tripItemId}/cancel`,
  },
  VIP_HALL: {
    GET_REFUND: (tripItemId) => `/viphall-bo/${tripItemId}/refund`,
    CANCEL_VOUCHER: (tripItemId) => `/viphall-bo/${tripItemId}/cancel`,
  },
  STATISTIC: {
    FUNCTIONAL: (accountId) => `/statistics/functional/${accountId}`,
    COMPLAINTS: '/client-statistics/complaints',
    SAVE_BONUSES: (accountId) => `/client-statistics/functional/${accountId}/bonusesgiftsloyalty`,
  },
  COMPANIES: {
    CARD_PAYMENT_FEE: (companyId) => `/companies/${companyId}/cardpayment/settings`,
    ADD_PLAN_FEE: '/plan-fee-service/planfee/manual',
    SEARCH_ACCOUNTS: '/account-service/companies/find',
    GET_COMPANY_DEBT: (companyId) => `/account-service/companies/${companyId}/has-debt`,
    GET_TAX_SHEMAS_SA: (companyId) => `/account-service-closing/company/taxation/${companyId}`,
    SET_TAX_SHEMAS_SA: '/account-service-closing/company/taxation',
  },
  EMPLOYEE: { GET_ALL_EMPLOYEE: (account_id) => `/employee-service/employees/account/${account_id}/backoffice` },
  BACKOFFICE_REPORTS: {
    FILE: '/files/file',
    BOOKING_WL: (startDate, endDate) => `/backoffice-reports/booking-wl/${startDate}/${endDate}/download`,
    BOOKING_SPECIAL_BILLING: (startDate, endDate) => (
      `/backoffice-reports/booking-special-billing/${startDate}/${endDate}/download`
    ),
    INSURANCE: (startDate, endDate) => `/backoffice-reports/insurance/${startDate}/${endDate}/download`,
    INSURANCE_FILTERED: (startDate, endDate) => (
      `/backoffice-reports/insurance-filtered/${startDate}/${endDate}/download`
    ),
    INSURANCE_CONSOLIDATE: (startDate, endDate) => (
      `/backoffice-reports/insurance-consolidated/${startDate}/${endDate}/download`
    ),
    REFUNDS_ONELIYA: (startDate, endDate) => `/backoffice-reports/train/refund/list/${startDate}/${endDate}/download`,
    GET_ABOARD_LIST: (startDate, endDate) => `/backoffice-reports/foreign-hotels/${startDate}/${endDate}`,
    UPDATE_ABOARD_CHECKBOXES: (tripItemId) => `/backoffice-reports/foreign-hotels/${tripItemId}/update`,
    DOWNLOAD_ALVIST_REPORT: (startDate, endDate) => `/backoffice-reports/alvist/${startDate}/${endDate}`,
    HOTEL_VAT: (startDate, endDate) => `/backoffice-reports/internal-vat/hotels/${startDate}/${endDate}/missing`,
    AA_VAT: (startDate, endDate) => `/backoffice-reports/internal-vat/hotels/${startDate}/${endDate}/aanda/problems`,
  },
  TRIP: {
    GET_COMMENTS: (tripId) => `/trip-service/trip/${tripId}/comment`,
    GET_FLIGHT_CERTIFICATE: (airItemId, signatureType) => (
      `/booking-service-documents/certificates/download/${airItemId}?type=${signatureType}`
    ),
    GET_FREE_FLIGHT_CERTIFICATE: (airItemId, signatureType) => (
      `/booking-service-documents/certificates/download/${airItemId}?type=${signatureType}&isFree=true`
    ),
    GET_STATUS_FLIGHT_CERTIFICATE: (airItemId) => `/booking-service-documents/${airItemId}/status`,
    BOOK_FLIGHT_CERTIFICATE: (airItemId) => `/flight-certificate/${airItemId}`,
    GET_TAXI_VOUCHER: (id) => `/taxi-voucher/search?query=${id}`,
    SEND_VERSION_TIME: ({
      tripId, tripItemId, tripItemVersionId,
    }) => `/trip/${tripId}/item/${tripItemId}/version/${tripItemVersionId}`,
    SEND_CUSTOM_VOUCHER: '/files/file/s3/customs/',
    SEND_BUS_VOUCHER: '/files/file/s3/bus/',
    SEND_AIRLINE_ADDITIONAL_VOUCHER: '/files/file/airline/additional-services',
    GET_LEGAL_STATUS: (tripItemId) => `/aa-provider/orders/contractor/${tripItemId}`,
    SEND_LEGAL_ENTITY: '/aa-provider/orders/contractor',
    GET_LEGAL_LIST: '/aa-provider/contractors',
    GET_FILE: (id) => `/file/${id}/get`,
    AUTOCOMPLETE_COUNTRY: (str) => `/enums-service/citizenship/query/${str}`,
    GET_TRIP_INFO_BY_ID: (id, accountId) => `/trip-service/autocomplete/${accountId}/${id}`,
    MERGE_TRIPS: (id) => `/trip-service/merge/${id}`,
    EVENTS: {
      GET_APPLICATION: '/mice-service/mice/event/bo/filtered',
      SET_APPLICATION: '/mice-service/mice/trip/link',
      CREATE_APPLICATION: '/mice-service/mice/event/bo',
      CANCEL_APPLICATION: (id) => `/mice-service/mice/event/cancel/${id}`,
      DELETE_APPLICATION: (id) => `/mice-service/mice/event/${id}`,
      UNPIN_APPLICATION: (id) => `/mice-service/mice/trip/unlink/${id}`,
    },
    ESTIMATE: (id) => `/trip-service/trip/${id}/estimate`,
    ADD_VAT_VERSIONS: '/vat-service/internal-vat/hotels/trip/items/versions',
    GET_VAT_AMOUNT: '/vat-service/vat/calculator',
    GET_VALIDATION: '/vat-service/internal-vat/hotels/validation',
    GET_CURRENCY_SUM: '/hotel-service/currency/calculator',
    EXCHANGE_CURRENCY: (currency) => `/hotel-service/currency-rate?code=${currency}`,
    CANCEL_CURRENCY_AMOUNT: '/hotel-service/currency/cancel-amounts',
    BOOKING_MARKUP: '/hotel-service/booking-markup',
    INSURANCE: {
      GET_ITEMS: (id) => `/insurance-service/trip/${id}/calculate`,
      RECALCULATE_RATES: '/insurance-service/trip/recalculate',
    },
    EDIT_AIRLANES_TICKET_NUMBER: (id) => `/trip-service/trip/item/version/${id}/ticket`,
    GET_AIRLANES_TICKET_CLOSING: (id) => `/account-service-closing/DocumentPackage/trip/item/versions/${id}/packages`,
    DOWNLOAD_TRIP_APPROVAL_REPORT: (tripId) => `/approve-service-report/backoffice/trip/${tripId}/download`,
    CHECK_TRIPS: '/trips/move/check',
    MOVE_TRIPS: (companyId) => `/trips/companies/${companyId}/move`,
    GET_OPERATIONS: '/account-service-billing/operation/list',
    UPDATE_PRICE: (versionId) => `/trip-service/trip/item/version/${versionId}/price`,
    GET_EMPLOYEE_TRIPS_BY_ACCOUNT_ID: (accountId, employeeId) => `/trip-service/cache/account/${accountId}/employee/${employeeId}/active`,
  },
  ACCOUNT: {
    GET_SMART_AGENT: (accountId) => `/account-service/account/smart-agent/check?accountid=${accountId}`,
    GET_SMART_AGENT_AUTO: (accountId) => `/account-service/account/auto-register/check?accountid=${accountId}`,
    GET_SMARTWAY_KZ: (accountId) => `/account-service/brand/${accountId}`,
    SAVE_LEAD_TYPE: '/account-service/LeadType',
    GET_LEAD_TYPE: (accountId) => `/account-service/LeadType/${accountId}`,
    DELETE_LEAD_TYPE: (accountId) => `/account-service/LeadType/${accountId}`,
  },
  PERSONAL_TRANSACTION: {
    GET_TRANSACTIONS: '/personal-transaction-service/transaction/bo',
    SEND_RECEIPT_TO_EMAIL: (receiptId) => `/personal-transaction-service/receipt/${receiptId}/send`,
  },
  USERS: {
    ADD_S_A_USER: '/smart-agent',
    AUTO_REGISTER: '/auto-register',
    BRAND_KZ: '/branded',
  },
  CART: {
    USER_INFO: (id) => `/user-service/user/${id}`,
    CART_BO: (id) => `/cart-service/cart-bo/${id}`,
    CREATE_CART: '/cart-service/cart-bo/offline',
    CREATE_ITEM_CART: (id) => `/cart-service/cart-bo/${id}/items`,
    EDIT_ITEM_CART: (id, itemId) => `/cart-service/cart-bo/${id}/items/${itemId}`,
    GET_APPROVALS: (id) => `/travel-approval/backoffice/cart/${id}/approvers`,
    APPROVE_ITEM: '/approve',
    SEND_ANALYTICS: (id) => `/cart-service/cart-bo/${id}/analytics`,
    DELETE_ANALYTICS: (cartId, analyticId) => `/cart-service/cart-bo/${cartId}/analytics/${analyticId}`,
    SAVE_INTERCOM_LINK: (cartId) => `/cart-service/cart-bo/${cartId}/intercom-link`,
    GET_BOOKING_REQUEST_BY_CART_ID: (cartId) => `/hotel-manual/requests?cartId=${cartId}`,
  },
  WARNING: {
    GET_LIST_TEMPLATE: (type) => `/informant/template/list/${type}`,
    ADD_TEMPLATE: '/informant/template/add',
    CHANGE_TEMPLATE: '/informant/template/update',
    DELETE_TEMPLATE: (widgetId) => `/informant/template/delete/${widgetId}`,
  },
  AMOCRM: {
    DUPLICATE_REPORT: '/amocrm/duplicate/report',
    GET_INFO_CONTACTS: '/amocrm/upload/list',
    DOWNLOAD_CONTACTS: (id) => `/amocrm/upload/${id}/report`,
  },
  UPLOAD_SERVICES: {
    UNLOAD_EMPLOYEES: (companyId) => `/upload-service/employees/companies/${companyId}/export`,
    SEND_UPLOAD_EMPLOYEE_FILE: (companyId) => `/upload-service/employees/companies/${companyId}/validate`,
    APPROVE_DOWNLOAD_EMPLOYEES: '/upload-service/employees',
    SEND_BULK_UPLOAD_FILE: (accountId) => `/upload-service/employees/accounts/${accountId}/settings`,
    DOWNLOAD_PATTERN: (type) => `/upload-service/employees/template?type=${type}`,
    DOWNLOAD_PATTERN_RIGHTS: (type, companyId) => (
      `/upload-service/employees/template?type=${type}&companyId=${companyId}`
    ),
    DOWNLOAD_RIGHTS_EMPLOYEES: (accountId, companyId) => (
      `/upload-service/employees/accounts/${accountId}/companies/${companyId}/rights`
    ),
  },
  PRODUCT_ANALYTICS: { SEND: '/product-analytics/bulk-insert' },
  CLIENT_SESSION: { GENERATE_PASSWORD: '/auth/bo-backdoor/generate-password' },
  INTERCOM: {
    SEND_NOTE: (requestId) => `/hotel-manual/requests/${requestId}/intercom-note`,
    SEND_TICKET: (requestId) => `/hotel-manual/requests/${requestId}/intercom-ticket`,
  },
  CLOSING_CONSTRUCTOR: {
    REGISTRY: {
      UPLOAD: (companyId) => (
        `/account-service-closing/closing-constructor/registry-constructor/upload?companyId=${companyId}`
      ),
      LIST: (companyId) => (
        `/account-service-closing/closing-constructor/registry-constructor/list?companyId=${companyId}`
      ),
      UPDATE_NAME: (companyId) => (
        `/account-service-closing/closing-constructor/registry-constructor/update-registry-name?companyId=${companyId}`
      ),
      REMOVE: '/account-service-closing/closing-constructor/registry-constructor/delete-templates',
      BUILD: (registryTemplateId, format) => (
        `/account-service-closing/closing-constructor/registry-constructor/build-preview/${registryTemplateId}?format=${format}`
      ),
      GET_TEMPLATE: (registryTemplateId) => (
        `/account-service-closing/closing-constructor/registry-constructor/template/${registryTemplateId}`
      ),
      LOAD_REGISTRIES: (companyId) => (
        `/account-service-closing/closing-constructor/registry-constructor/get-added-registries?companyId=${companyId}`
      ),
      ADD: (companyId) => (
        `/account-service-closing/closing-constructor/registry-constructor/add?companyId=${companyId}`
      ),
      UPDATE_DATE: (constructedRegistryId, newDocumentDate, companyId) => (
        `/account-service-closing/closing-constructor/registry-constructor/update-registry-date?constructedRegistryId=${constructedRegistryId}&newDocumentDate=${newDocumentDate}&companyId=${companyId}`
      ),
      UPDATE_FILETYPE: (constructedRegistryId) => (
        `/account-service-closing/closing-constructor/registry-constructor/update-registry-file-types?constructedRegistryId=${constructedRegistryId}`
      ),
      REMOVE_REGYSTRY: (constructedRegistryId) => (
        `/account-service-closing/closing-constructor/registry-constructor/delete-registry?constructedRegistryId=${constructedRegistryId}`
      ),
    },
  },
  DOCUMENTS: {
    GET_TEMPLATES: (packageId) => `/account-service-closing/document/unique-templates/for-package/${packageId}`,
    ADD_DOCUMENT: '/account-service-closing/document/from-template',
  },
  UNLOADING1C: {
    SEND_SETTINGS: '/accounting-ones-reports-service/report/file-dependency',
    GET_WORKSHEETS: '/accounting-ones-reports-service/excel/get-worksheets',
    GET_PRINCIPALS: '/accounting-ones-reports-service/reference-entities/legal-entities/autocomplete',
    GET_NOMENCLATURES: '/accounting-ones-reports-service/reference-entities/nomenclatures/autocomplete',
  },
  SWAGGER: { REPLACE: '/oas-utilities/receive-cookie' },
  VACATION: {
    FIND_ACCOUNTS: (query) => `/api-vacation-backoffice/account/search/${query}`,
    GET_USER_PROFILE: (companyId) => `/api-vacation-backoffice/account/company/${companyId}/user/profile`,
    GET_TRIPS: (companyId) => `/api-vacation-backoffice/company/${companyId}/trips`,
    GET_POINTS_TRANSACTIONS: (companyId) => `/api-vacation-backoffice/company/${companyId}/bonuses`,
    GET_TRAVELLERS: '',
  },
  BOOKING: {
    GET_SETTINGS: '/booking-settings-service/booking/settings',
    SET_SETTINGS: '/booking-settings-service/booking/settings',
    RESET_SETTINGS: '/booking-settings-service/booking/settings/reset',
  },
  CUSTOM_ANALYTICS: {
    GET_ANALYTICS_WITHOUT_VALUES: (accountId) => `/user-analytics/account/${accountId}/without-values`,
    GET_ANALYTICS_BY_VALUE: '/user-analytics/values/by-ids',
    AUTOCOMPLETE_ANALYTICS: (accountId, analyticId) => (
      `/user-analytics/account/${accountId}/analytic/${analyticId}/values/by-name`
    ),
  },
};

export default ROUTES;
